import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { IvyCarouselModule } from 'angular-responsive-carousel';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { provideRouter } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TapToLearnComponent } from '../tap-to-learn/tap-to-learn.component';
import { CatalogComponent } from './catalog/catalog.component';
import { CategoriesSidebarComponent } from './category-products/categories-sidebar/categories-sidebar.component';
import { CategoryProductsComponent } from './category-products/category-products.component';
import { LevelOneCategoriesSidebarComponent } from './category-products/level-one-categories-sidebar/level-one-categories-sidebar.component';
import { MiniProductCardComponent } from './category-products/mini-product-card/mini-product-card.component';
import { SubcategoryFilterComponent } from './category-products/subcategory-filter/subcategory-filter.component';
import { InvalidCategoryComponent } from './invalid-category/invalid-category.component';
import { NoSearchResultsComponent } from './no-search-results/no-search-results.component';
import { RequestUnavailableProductComponent } from './no-search-results/request-unavailable-product/request-unavailable-product.component';
import { ProductActionsComponent } from './product-details/product-actions/product-actions.component';
import { ProductDetailsAdditionalInfoComponent } from './product-details/product-details-additional-info/product-details-additional-info.component';
import { ProductDetailsBasicInfoComponent } from './product-details/product-details-basic-info/product-details-basic-info.component';
import { ProductQuantityOffersComponent } from './product-details/product-details-basic-info/product-quantity-offers/product-quantity-offers.component';
import { ProductDetailsGalleryComponent } from './product-details/product-details-gallery/product-details-gallery.component';
import { ProductDetailsPreviewComponent } from './product-details/product-details-preview/product-details-preview.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductsSharedModule } from './products-shared.module';
import { CategoryCardComponent } from './products-v2/category-card/category-card.component';
import { ProductsCarouselComponent } from './products-v2/products-carousel/products-carousel.component';
import { ProductsV2Component } from './products-v2/products-v2.component';
import { SubBannerComponent } from './products-v2/sub-banner/sub-banner.component';
import { TopBannerComponent } from './products-v2/top-banner/top-banner.component';

@NgModule({
  imports: [
    CommonModule,
    IvyCarouselModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatIconModule,
    MatDialogModule,
    MatChipsModule,
    TranslateModule,
    /**
     * onboarding module will need to be imported so that it's ui pieces which need to be on the landing
     * page (i.e products list page), for example progress indicator, can be exported from it.
     */
    ProductsSharedModule,
    ProductsV2Component,
    CategoryCardComponent,
    TopBannerComponent,
    SubBannerComponent,
    ProductsCarouselComponent,
    CategoryProductsComponent,
    CategoriesSidebarComponent,
    MiniProductCardComponent,
    ProductDetailsComponent,
    CatalogComponent,
    RequestUnavailableProductComponent,
    SubcategoryFilterComponent,
    InvalidCategoryComponent,
    NoSearchResultsComponent,
    LevelOneCategoriesSidebarComponent,
    TapToLearnComponent,
    ProductDetailsAdditionalInfoComponent,
    ProductActionsComponent,
    ProductDetailsBasicInfoComponent,
    ProductQuantityOffersComponent,
    ProductDetailsGalleryComponent,
    ProductDetailsPreviewComponent,
  ],
  providers: [
    provideRouter([
      { path: 'category', component: CategoryProductsComponent },
      { path: 'category/:id', component: CategoryProductsComponent },
      { path: 'catalog', component: CatalogComponent },
      { path: 'invalid-category', component: InvalidCategoryComponent },
      { path: 'no-search-results', component: NoSearchResultsComponent },
      { path: ':id', component: ProductDetailsComponent },
      { path: '', component: ProductsV2Component, pathMatch: 'full' },
    ]),
  ],
})
export class ProductsModule {}
